import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import Image from "../assets/images/photos/injury-lawyer.webp";
export default function GgLp2() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const contentData = {
    heading: `Protecting Your Rights  <br/>
After an Injury`,
    subheading: `Fast, Effective <br/>
Legal Services Tailored  <br/>
To Your Needs`,
    text: "",
    backgroundImage: Image, // Replace with actual path
  };
  const ContactCnt = {
    Heading: `We Are Here to Help <br/>
Contact Us for <br/>
Top Legal Assistance`,
  };
  const PhoneNumber = {
    PhoneNo: "(888) 845-3824",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection contentData={contentData} PhoneNumber={PhoneNumber} />
    
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactCnt={ContactCnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
