import React from "react";
import icon1 from "../assets/images/icons/free and easy.png";
import icon2 from '../assets/images/icons/personalized service.png';
import icon3 from '../assets/images/icons/trusted network.png';
export default function Services() {
  const servicesData = [
    {
      title: "Trusted Network",
      description: "Our extensive network includes only the highest-rated bankruptcy attorneys.",
      Image: icon3
    },
    {
      title: "Personalized Service",
      description: "We tailor our recommendations to fit your unique financial situation and legal needs.",
      Image: icon2
    },
    {
      title: "Free & Easy",
      description: "Our referral service is completely free and designed to make finding an attorney effortless.",
      Image: icon1
    }
  ];

  return (
    <div className="Service">
 <div className="services-section">
      <div className="services-content">
        <h1 className="services-heading">Why Choose Us?</h1>
        <div className="services-cards">
          {servicesData.map((service, index) => (
            <div key={index} className="services-card">
              <img src={service.Image} alt="" />
              <h3 className="services-card-title">{service.title}</h3>
              <p className="services-card-description">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
   
  );
}
