import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import Image from "../assets/images/photos/miserable-woman-crying-while-calling-help-mobile-phone-after-car-accident.webp";
export default function DlLp1() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const contentData = {
    heading: `Injured? <br/>
Get Expert Legal <br/>
Help Now`,
    subheading: `Your Direct Connection <br/>
To <br/>
Legal Experts Near You`,
    text: "",
    backgroundImage: Image, // Replace with actual path
  };
  const ContactCnt = {
    Heading: `Your Path to Justice <br/>
Starts Here <br/>
Call Now to Connect with <br/>
Premier Attorneys`,
  };
  const PhoneNumber = {
    PhoneNo: "(888) 383-1238",
  };
  return (
    <div>
       <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection contentData={contentData} PhoneNumber={PhoneNumber} />
    
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactCnt={ContactCnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
