import React, { useRef, useEffect } from "react";
import scrollAnimation from "../../utils/scrollAnimation";


export default function HeroSection({ contentData, PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  
  return (
    <div
      className="HeroSection"
      style={{
        backgroundImage: `url(${contentData.backgroundImage})`,
      }}
    >
      <div className="hero-content">
        <h1 ref={cardRefs[0]} dangerouslySetInnerHTML={{__html: contentData.heading}}/>
        <h2 ref={cardRefs[1]} dangerouslySetInnerHTML={{__html: contentData.subheading}}/>
        
        <p className="hero-text">{contentData.text}</p>
        <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
      </div>
    </div>
  );
}
