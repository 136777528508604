import React from 'react';
import Image from "../assets/images/photos/Group 50.webp";
import icon1 from "../assets/images/icons/car accident.png";
import icon2 from "../assets/images/icons/Slip & Fall Accidents.png";
import icon3 from "../assets/images/icons/medical malpractice.png";
import icon4 from "../assets/images/icons/Workplace Accident.png";
export default function WhyChooseUs({ PhoneNumber }) {
  const steps = [
    {
      id: 1,
      title: 'Car Accident',
      description: 'If you’ve been in a car accident, you may be entitled to compensation for damages like medical costs, pain, suffering, and lost income. We connect you with the best personal injury attorney in your area.',
      Image: icon1
    },
    {
      id: 2,
      title: 'Medical Malpractice',
      description: `Medical malpractice is alarmingly common. Don't settle for less if you or a loved one has been a victim. Consult with a local personal injury attorney today to ensure your rights are protected.` ,
      Image: icon3
    },
    {
      id: 3,
      title: 'Slip & Fall Accidents ',
      description: 'Suffered a slip and fall accident due to debris, liquid, or ice on a walking surface? The property owner may be liable for your injuries. Get guidance from an Injury Attorney to claim your compensation.',
      Image: icon2
    },
    {
      id: 4,
      title: 'Workplace Accident ',
      description: 'Injured at work? You have legal rights to claim compensation. Navigating these claims can be complex and time-sensitive. Get expert advice to secure a quick consultation for financial compensation.',
      Image: icon4
    },
  ];

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className='whyChooseUs'>
      <h2>Most Common Cases</h2>
       <div className="why-choose-us-container">
      {/* <div className="why-choose-us-left">
        <h2>How it all works</h2>
        <h5>Donec sagittis sagittis vestibulum. Morbi vestibulum neque.</h5>
      </div> */}

      <div className="why-choose-us-center">
        <img src={Image} alt="Center Image" />
      </div>

      <div className="why-choose-us-right">
        {steps.map((step) => (
          <div key={step.id} className="step">
            <div className="step-number">{step.id}</div>
            <div className="step-content">
              <div className='step_img'>
                <img src={step.Image} alt="" />
              </div>
              <div className='step_c'><h4>{step.title}</h4>
              <p>{step.description}</p></div>
              
            </div>
          </div>
        ))}
      </div>

      
    </div>
        <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
    </div>

   
  );
}
