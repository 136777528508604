import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import Image from "./assets/images/photos/asian-woman-patient-wearing-soft-splint-due-sore-arm-broken-head-isolated-white-background-personal-accident-insurance-concept.webp";
export default function landingPage() {
  const contentData = {
    heading: "HAVE YOU BEEN INJURED?",
    subheading: "Experience Expert Support for Your Injury Case",
    text: "",
    backgroundImage: Image, // Replace with actual path
  };
  const ContactCnt = {
    Heading: `Find Your Perfect Advocate
Get Matched with Leading Injury Lawyers Today`,
  };
  const PhoneNumber = {
    PhoneNo: "(888) 383-1238",
  };

  return (
    <div className="lp">
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection contentData={contentData} PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactCnt={ContactCnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
