import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import Image from "../assets/images/photos/front-view-young-male-sitting-with-broken-foot-crutches-thinking-grey-wall-broken-accident-foot-legs-pain-twist.webp";
export default function GgLp3() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const contentData = {
    heading: `Quick Legal<br/> Consultation 
for  Your <br/> Injury Claim`,
    subheading: `Get Matched with <br/>
Local <br/>
Leading Injury Attorneys`,
    text: "",
    backgroundImage: Image, // Replace with actual path
  };
  const ContactCnt = {
    Heading: `Take the First Step to Justice <br/>
Expert Legal Help is <br/>
Just a Phone Call Away`,
  };
  const PhoneNumber = {
    PhoneNo: "(888) 845-3824",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection contentData={contentData} PhoneNumber={PhoneNumber} />
    
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Contact ContactCnt={ContactCnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
